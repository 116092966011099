import { useState, useEffect } from "react";
import * as ApiHelper from "@/services/apiHelper";
import "./Modal.scss";
import * as errors from "@/constants/stringConstants";
import { CURRENCY } from "@/constants/storageConstants";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";

import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";
import Text from "../_widgets/Text/Text";
import { isCandidate } from "@/utils/authUtil";
import { removeParameterFromURL } from "../../utils/stringUtils";
import useRazorpay from "react-razorpay";
import { getUserBasicDetails } from "../../utils/authUtil";
import Button from "../_widgets/Button/Button";
import { MAX_AMOUNT_REQUIRED } from "../../constants/stringConstants";

function ModalBuyCash({
  showModal,
  setShowModal,
  ttCashDollar,
  companyId,
  getUsers,
  type,
}) {
  let [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState({
    offline: false,
    online: false,
  });
  let [amount, setAmount] = useState(0);
  let [isLivePayment, setIsLivePayment] = useState(false);
  const Razorpay = useRazorpay();

  const payOffline = async () => {
    var isError = 0;

    if (amount <= 0) {
      isError = 1;
      toast.warn(errors.AMOUNT_GREATER_ZERO);
    }

    if (isError === 0) {
      const tempAmount = amount * ttCashDollar * (1 + gstPercent / 100);

      const formData = new FormData();
      formData.append("company_id", companyId);
      formData.append("amount", tempAmount);
      setButtonLoading({ ...buttonLoading, offline: true });
      await ApiHelper.offlinePayment(formData).then((response) => {
        setButtonLoading({ ...buttonLoading, offline: false });
        if (response.isSuccess === true) {
          toast.success(response.message);
          getUsers();
          closeModal();
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const placeOrder = async () => {
    if (amount < 1) {
      toast.warn("Minimum amount should be 1");
      return false;
    }

    const tempAmount = amount * ttCashDollar * (1 + gstPercent / 100);

    const formData = new FormData();
    formData.append("amount", tempAmount);
    formData.append(
      "redirect_url",
      removeParameterFromURL(window.location.href, "tid")
    );

    setButtonLoading({ ...buttonLoading, online: true });
    ApiHelper.placeOrder(formData).then((response) => {
      if (response.isSuccess === true) {
        if (response?.data?.gateway === "phonepe") {
          var a = document.createElement("a");
          a.href = response.data.url;
          a.click();
        } else if (response?.data?.gateway === "razorpay") {
          initiateRazorpay(
            response.data.key,
            response.data.order_id,
            response.data.amount,
            response.data.currency
          );
        } else {
          toast.warn(response.message);
        }
        setButtonLoading({ ...buttonLoading, online: false });
      } else {
        setButtonLoading({ ...buttonLoading, online: false });
      }
    });
  };

  const verifyPayment = async (
    razorPayPaymentId,
    razorPayOrderId,
    razorPaySignature
  ) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("razorpay_order_id", razorPayOrderId);
    formData.append("razorpay_payment_id", razorPayPaymentId);
    formData.append("razorpay_signature", razorPaySignature);

    ApiHelper.verifyRazorpayPayment(formData).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        setLoading(false);
        getUsers();
        closeModal();
      } else {
        toast.warn(response.message);
        setLoading(false);
      }
    });
  };

  const initiateRazorpay = async (
    razorPayKey,
    razorPayOrderId,
    amount,
    currency
  ) => {
    var userDetails = getUserBasicDetails();
    var options = {
      key: razorPayKey,
      amount: amount,
      currency: currency,
      order_id: razorPayOrderId,
      handler: function (response) {
        verifyPayment(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },
      prefill: {
        name: userDetails["full_name"],
        email: userDetails["email"],
        contact: "",
      },
    };
    var rzp = new Razorpay(options);
    rzp.on("payment.failed", function (response) {
      toast.warn(response.error.description);
      console.error("errorResponse", response);
    });
    rzp.open();
  };

  const closeModal = () => {
    setAmount(0);
    setIsLivePayment(false);
    setShowModal((prev) => !prev);
  };

  const [masterData] = useState(
    commonServices.getLocalData(storageConstants.DATA)
  );

  const [gstPercent, setGstPercent] = useState(0);

  useEffect(() => {
    if (masterData) {
      setGstPercent(masterData.system_config.gst.value);
    }
  }, [masterData]);

  return (
    <AppModal
      title={"Buy TT Cash (1 TT Cash - Rs 1)"}
      isOpen={showModal}
      closeModal={() => {
        closeModal();
      }}
      isFooter={false}
      size={"lg"}
      loading={loading}
      style={{ height: "180vh", overflowY: "auto" }}
    >
      <div className="modal_body">
        {!isLivePayment ? (
          <>
            <div className="row">
              <div className="col">
                <label className="input-lebel">
                  Select an amount from the below table or enter your preferred
                  amount:
                </label>
                <div
                  className="btn-toolbar text-center my-2"
                  role="toolbar"
                  aria-label="Toolbar with quick amount groups"
                >
                  <div
                    className="btn-group no-scroll"
                    style={{ minWidth: "100%" }}
                    role="group"
                    aria-label="quick amount"
                  >
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(500)}
                      text="500"
                    ></Button>
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(750)}
                      text="750"
                    ></Button>
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(1000)}
                      text="1,000"
                    ></Button>
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(5000)}
                      text="5,000"
                    ></Button>
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(10000)}
                      text="10,000"
                    ></Button>
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(20000)}
                      text="20,000"
                    ></Button>
                    <Button
                      buttonType="amountBtn"
                      onClick={() => setAmount(25000)}
                      text="25,000"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="input-label">TT Cash Amount</label>
                <input
                  type="text"
                  min="100"
                  max="999999"
                  value={commonServices.currencyFormatter(amount)}
                  onChange={(e) => {
                    let inputValue = commonServices.removeCommaFromAmount(e.target.value);
                    if (commonServices.isValidCurrency(inputValue)) {
                      if (Number(inputValue) > 99999) {
                        inputValue = '99999';
                        toast.warn(MAX_AMOUNT_REQUIRED)
                      }
                      setAmount(inputValue);
                    }
                  }}
                  className="form-control"
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="extra">
          {amount >= 100 && amount <= 99999 ? (
            <>
              {!isLivePayment ? (
                <div className="row">
                  <div className="col">
                    <div
                      className="alert alert-info mt-2 due-amount-bar"
                      role="alert"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "1rem",
                          maxWidth: "300px",
                          margin: "auto",
                        }}
                      >
                        <div style={{ gridColumn: "span 2" }}>
                          <Text
                            text="Purchase Amount: "
                            style={{ textAlign: "left" }}
                          />
                        </div>
                        <Text
                          text={`${CURRENCY} ${commonServices.currencyFormatter(
                            amount * ttCashDollar
                          )}`}
                          style={{ textAlign: "right" }}
                        />

                        <div style={{ gridColumn: "span 2" }}>
                          <Text
                            text={`Tax(${gstPercent}%):`}
                            style={{ textAlign: "left" }}
                          />
                        </div>
                        <Text
                          text={`${CURRENCY} ${commonServices.currencyFormatter(
                            (amount * ttCashDollar * gstPercent) / 100,
                            false
                          )}`}
                          style={{ textAlign: "right" }}
                        />

                        <div style={{ gridColumn: "span 2" }}>
                          <Text
                            text="Payable Amount:  "
                            style={{ textAlign: "left" }}
                          />
                        </div>
                        <Text
                          text={`${CURRENCY} ${commonServices.currencyFormatter(
                            amount * ttCashDollar * (1 + gstPercent / 100),
                            false
                          )}`}
                          style={{ textAlign: "right" }}
                        />

                        <div style={{ gridColumn: "span 2" }}>
                          <Text
                            text="Rounding Off:  "
                            style={{ textAlign: "left" }}
                          />
                        </div>
                        <Text
                          text={`${CURRENCY} ${commonServices.currencyFormatter(
                            commonServices.removeCommaFromAmount(
                              amount * ttCashDollar * (1 + gstPercent / 100),
                              true
                            ) -
                            commonServices.removeCommaFromAmount(
                              amount * ttCashDollar * (1 + gstPercent / 100),
                              false
                            ),
                            false
                          )}`}
                          style={{ textAlign: "right" }}
                        />

                        <div style={{ gridColumn: "span 2" }}>
                          <Text
                            text="Total Payable Amount:  "
                            style={{ textAlign: "left" }}
                          />
                        </div>
                        <Text
                          text={`${CURRENCY} ${commonServices.currencyFormatter(
                            amount * ttCashDollar * (1 + gstPercent / 100)
                          )}`}
                          style={{ textAlign: "right" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {!isLivePayment ? (
                <div className="row">
                  <div className="col">
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {/* {!isCandidate() ? (
                        <Button
                          onClick={() => payOffline()}
                          buttonType="secondary"
                          disabled={buttonLoading.offline}
                          isLoading={buttonLoading.offline}
                          text="Pay Offline"
                        ></Button>
                      ) : (
                        <></>
                      )} */}
                      <Button
                        onClick={() => placeOrder()}
                        buttonType="primary"
                        disabled={buttonLoading.online}
                        isLoading={buttonLoading.online}
                        text="Pay Online"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div className="row" style={{ padding: "0px 16px" }}>
              <div
                className="alert alert-info mt-2 error-bar"
                role="alert"
                style={{
                  color: "#FC3400",
                  backgroundColor: "#FADEDD",
                  borderColor: "#FADEDD",
                  fontWeight: "700",
                }}
              >
                {amount < 100
                  ? "Minimum TT Cash Amount should be greater than 99"
                  : "Maximum TT Cash Amount should be less than 99999"}
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
}

export default ModalBuyCash;
